import style from './style.module.scss';
import placeholder from '../../../../assets/images/success.png';
import {JSX} from "react";
interface ISuccessPopUp {
    title: string;
    subtitle?: string | JSX.Element | null;
    icon?: string;
}
const SuccessPopUp = ({title, subtitle = null, icon = placeholder}: ISuccessPopUp) => {
    return <div className={style.modalSuccess}>
        <img src={icon} alt={title}/>
        <h2>{title}</h2>
        {subtitle && <p>{subtitle}</p>}
    </div>
}
export default SuccessPopUp;
