import styles from './style.module.scss';

type LoaderProps = {
  isLoading: boolean;
  isInner?: boolean;
}

export const Loader = ({ isLoading, isInner = false }: LoaderProps) => {

  return (
    <div className={`${styles.loaderWrapper} ${isInner && styles.inner} ${isLoading && styles.active}`}>
      <div className={styles.ripple}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};