import style from './style.module.scss';
import logo from '../../../../assets/images/logo.svg';
const Header = () => {
    return <header className={style.header}>
        <div className="content">
            <img src={logo} alt=""/>
        </div>
    </header>
}

export default Header;