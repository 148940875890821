import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthApi } from './AuthApi';

export type LoginFormStep = 'SignIn' | 'SignUp' | 'ResetPwd' | 'ResetPwdConfirm' | 'ConfirmPhone'
interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isLogout: boolean;
  openSignModal: boolean;
  loginFormInitStep: LoginFormStep | null;
  inviteHash?: string | null;
  resetPasswordHash?: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  isLogout: false,
  openSignModal: false,
  loginFormInitStep: null,
  inviteHash: null,
  resetPasswordHash: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // setInviteHash: (state, action) => {
    //   state.inviteHash = action.payload
    // },
    // setResetHash: (state, action) => {
    //   state.resetPasswordHash = action.payload
    // },
    // setOpenSignModal: (state, {payload}: PayloadAction<boolean | { isOpen: boolean; formStep: LoginFormStep }>) => {
    //   if (typeof payload === 'object') {
    //     state.openSignModal = payload.isOpen
    //     state.loginFormInitStep = payload.formStep
    //     return
    //   }
    //   state.openSignModal = payload
    // },
    // resetLoginFormStep: (state) => {
    //   state.loginFormInitStep = null
    // },
    // logout: (state) => {
    //   state.accessToken = null
    //   state.isLogout = true
    //   state.refreshToken = null
    // },
    // setAccessToken: (state, { payload }: PayloadAction<string | null>) => {
    //   state.accessToken = payload
    // },
  },
  extraReducers: (builder) => {
    // builder.addMatcher(AuthApi.endpoints.login.matchFulfilled, (state, { payload }) => {
    //   state.accessToken = payload.accessToken
    //   state.refreshToken = payload.refreshToken
    // })
    // builder.addMatcher(AuthApi.endpoints.checkIsBaned.matchFulfilled, (state, { payload }) => {
    //   state.userStatus = payload
    // })
    // builder.addMatcher(AuthApi.endpoints.socialLogin.matchFulfilled, (state, { payload }) => {
    //   state.accessToken = payload.accessToken
    //   state.refreshToken = payload.refreshToken
    // })
    // builder.addMatcher(AuthApi.endpoints.confirmEmail.matchFulfilled, (state, { payload }) => {
    //   state.accessToken = payload.accessToken
    //   state.refreshToken = payload.refreshToken
    // })
  },
})

export const {  } = authSlice.actions

export default authSlice.reducer;
