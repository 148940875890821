import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../../customFetchBase';
import { IRegister } from './AuthTypes';

export const AuthApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    preRegister: builder.mutation<{success: boolean}, { body: IRegister }>({
      query: ({ body }) => ({
        url: 'auth/register/early',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  usePreRegisterMutation,
} = AuthApi
