import cx from 'classnames';
import { ChangeEvent, useId } from "react";
import styles from './styles.module.scss';

interface CheckboxProps {
  name?: string,
  label?: string | JSX.Element,
  checked?: boolean,
  onChange?: (e: ChangeEvent<HTMLInputElement>, state: boolean) => void,
  inputClassName?: string,
  containerClassName?: string,
  disabled?: boolean
}

const Checkbox = ({ label, checked, name, onChange, containerClassName, disabled }: CheckboxProps) => {
  const id = useId()
  return (
    <div className={cx(styles.checkboxWrapper, containerClassName, {[styles.disabled]: disabled})}>
      <label>
        <input
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange?.(e, !checked)}
          className={checked ? styles.checked : ""}
          disabled={disabled}
        />
        {label && <span>{label}</span>}
      </label>
    </div>
  );
};
export default Checkbox;
