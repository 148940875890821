import cx from 'classnames'
import { Suspense, forwardRef, memo, useEffect, useId, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useMediaQuery } from 'react-responsive'
import { CSSTransition } from 'react-transition-group'
import '../../../../assets/styles/transitions.scss'
import useDisableScroll from '../../../hooks/useDisableScroll'
import styles from './style.module.scss'

interface IModule extends ModalBodyProps {
  withPortal?: boolean
  preventScrollDisabling?: boolean
}

interface ModalBodyProps {
  children: any
  onClose: () => void
  mobileFullHeight?: boolean
  noPadding?: boolean
  isMobile?: boolean
  isPdfModal?: boolean
  enableBackground?: boolean
  width?: string
  scrollDisabled?: boolean
}

const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(
  ({ children, onClose, mobileFullHeight, noPadding, isMobile, enableBackground = false, isPdfModal = false, width, scrollDisabled = false }, ref) => (
    <div ref={ref} className={styles.outer}>
      {enableBackground && <div className={cx(styles.modalBackground)} onClick={onClose} />}
      <div
        style={{ minWidth: width }}
        className={cx(styles.modalWrapper, {
          [styles.full]: mobileFullHeight,
          [styles.noPaddings]: noPadding,
          [styles.mobile]: isMobile,
          [styles.pdfModal]: isPdfModal,
          [styles.scrollDisabled]: scrollDisabled
        })}>
        <div
          className={cx(styles.modal, {
            [styles.full]: mobileFullHeight,
            [styles.noPaddings]: noPadding,
            [styles.mobile]: isMobile,
            [styles.pdfModal]: isPdfModal,
          })}>
          <button className={styles.close} onClick={onClose}>
            <span className={'icon-close'} />
          </button>
          <div className={styles.moduleContent}>{children}</div>
        </div>
      </div>
    </div>
  )
)

const Modal = memo(
  ({
    children,
    onClose,
    withPortal,
    preventScrollDisabling,
    scrollDisabled,
    mobileFullHeight,
    noPadding,
    isMobile,
    enableBackground = false,
    isPdfModal = false,
    width,
  }: IModule) => {
    const ref = useRef(null)
    const uid = useId()
    const [isSmoothOpen, setIsSmoothOpen] = useState(false)
    const isMobileQuery = useMediaQuery({ query: '(max-width: 640px)' })

    useEffect(() => {
      setIsSmoothOpen(true)
    }, [])
    useDisableScroll(!preventScrollDisabling)

    return (
      <CSSTransition
        in={isSmoothOpen}
        nodeRef={ref}
        timeout={300}
        classNames={mobileFullHeight && isMobileQuery ? 'fade-scale' : 'tr-fade'}
        unmountOnExit
        onExited={onClose}>
        <Suspense>
          {withPortal ? (
            createPortal(
              <ModalBody
                key={uid}
                ref={ref}
                children={children}
                onClose={() => setIsSmoothOpen(false)}
                mobileFullHeight={mobileFullHeight}
                noPadding={noPadding}
                isMobile={isMobile}
                isPdfModal={isPdfModal}
                enableBackground={enableBackground}
                width={width}
                scrollDisabled={scrollDisabled}
              />,
              document.getElementById('root') || document.body
            )
          ) : (
            <ModalBody
              key={uid}
              ref={ref}
              children={children}
              onClose={() => setIsSmoothOpen(false)}
              mobileFullHeight={mobileFullHeight}
              noPadding={noPadding}
              isMobile={isMobile}
              enableBackground={enableBackground}
              width={width}
              isPdfModal={isPdfModal}
              scrollDisabled={scrollDisabled}
            />
          )}
        </Suspense>
      </CSSTransition>
    )
  }
)

export default Modal
