import { useEffect } from 'react';

export default function useDisableScroll(trigger: boolean) {
  useEffect(() => {
    if (trigger) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [trigger]);
}
