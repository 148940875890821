import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import style from "./style.module.scss";

interface IInputPhone {
    phone: string;
    onFormChange: (value: string) => void;
    error?: string | null;
    col?: 'col6' | 'col12' | 'unset';
    customClass?: string;
    noWrapperPadding?: boolean;
    label?: string
    disabled?:boolean
}
const InputPhone = ({error = null, phone, onFormChange, col = 'col12', customClass, noWrapperPadding = false, label, disabled }: IInputPhone) => {

    const handlePhoneChange = (phone: string) => {
        onFormChange(phone);
    }
    return (
        <div className={`${style.formCol} ${noWrapperPadding ? style.formColNoPadding : ''} ${style[col]} ${customClass ? style[customClass] : ''}`}>
            {label && <label>{label}</label>}
            <PhoneInput
                disabled={disabled}
                defaultCountry="us"
                value={phone}
                onChange={(phone) => handlePhoneChange(phone)}
                className={`${style.phoneInput} phone-input-form`}
            />
            {error && error !== ''  && <div className={style.error}>{error}</div>}
        </div>
    );
}

export default InputPhone;