import {IOption, IOptionExtended} from "../store/reducers/Auth/AuthTypes";

export const REG_EXP =
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'

export const getSelectOption = <T>(options: (IOption<T> | IOptionExtended<unknown, T>)[], value: T) => {
    const filterVal = options.filter((a) => a.value === value)
    if (filterVal && filterVal.length > 0) {
        return filterVal[0]
    } else {
        return null
    }
}