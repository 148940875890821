import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {persistor, store} from './app/store/store';
import reportWebVitals from './reportWebVitals';
import './assets/styles/reset.scss';
import './assets/styles/fonts.scss';
import './assets/styles/root.scss';
import './assets/styles/global.scss';
import './assets/styles/index.css';
import './assets/fonts/icon-fonts/style.css';
import Home from "./app/layout/views/Home";
import {PersistGate} from "redux-persist/integration/react";
import Header from "./app/layout/components/Header";
import Footer from "./app/layout/components/Footer";
import { BrowserRouter } from 'react-router-dom'

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                  <Header/>
                  <Home />
                  <Footer/>
              </BrowserRouter>
          </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
