import style from './style.module.scss';
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import {useEffect, useState} from "react";
import SignUp from "../../components/SignUp/signUp";
import {IRegister} from "../../../store/reducers/Auth/AuthTypes";
import SuccessPopUp from "../../components/SuccessPopUp";
import {usePreRegisterMutation} from "../../../store/reducers/Auth/AuthApi";
import {Loader} from "../../components/Loader";
import {toast} from "react-toastify";
import anim from '../../../../assets/animation/animation_map/data.json';
import {useLottie} from "lottie-react";

const FORM_MOCK: IRegister = {
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    surname: '',
    phone: '',
    company: '',
    userType: "AdSpaceOwner",
    description: '',
    needDesignHelp: false,
    agreePolicy: false
}
const Home = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [form, setForm] = useState<IRegister>(FORM_MOCK);
    const [formType, setFormType] = useState<'Advertiser' | 'Owner'>('Owner');
    const [preRegisterRequest, {isSuccess, isLoading, isError}] = usePreRegisterMutation();
    useEffect(() => {
        if (isSuccess) {
            setIsModalOpen(false);
            setIsSuccessModalOpen(true);
        }
        if (isError) {
            toast.error('Oops, something went wrong.');
        }
    }, [isError, isSuccess]);
    const onChangeForm = (value: string | boolean, key: string) => {
        setForm({...form, [key]: value});
    }
    const onSubmit = () => {
        const {confirmPassword, agreePolicy, password, needDesignHelp, description, ...rest} = form;
        let body: any = {
            ...rest
        }
        if (formType === 'Owner') {
            body = {
                ...body,
                password: password,
            }
        } else {
            body = {
                ...body,
                description: description,
                needDesignHelp: needDesignHelp
            }
        }
        preRegisterRequest({body: body});
    }
    const options = {
        animationData: anim,
        loop: true
    };
    const { View } = useLottie(options);
    return <>
        {isLoading && <Loader isLoading={isLoading}/>}
        <div className={style.homeWrapper}>
            <div className={`content ${style.content}`}>
                <div className={style.row}>
                    <div className={style.infoCol}>
                        <h1 className={style.title}>Connecting <br/>
                            <span>Brands</span> 🤝 with <br/>
                            Prime <span>Advertising</span> Opportunities</h1>
                        <p className={style.description}>Streamlining Your Path to Visibility and Growth</p>
                        <div className={style.btnGroup}>
                            <Button theme={'blue'} onClick={() => {
                                setForm({...form, userType: 'Advertiser'});
                                setFormType('Advertiser');
                                setIsModalOpen(true);
                            }} title={'I Own Ad Space'}/>
                            <Button theme={'secondary'} onClick={() => {
                                setForm({...form, userType: 'AdSpaceOwner'});
                                setFormType('Owner');
                                setIsModalOpen(true);
                            }} title={'I Want to Advertise'}/>
                        </div>
                    </div>
                    <div className={style.imageCol}>
                        <div className={style.heroAnimWrapper}>
                            <div className={style.imageColAnimation}>{View}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} withPortal={true} enableBackground={true}>
            <SignUp form={form} onSignUp={onSubmit} onChange={onChangeForm} userType={formType}/>
        </Modal>}
        {isSuccessModalOpen && <Modal onClose={() => setIsSuccessModalOpen(false)} withPortal={true} enableBackground={true}>
            <SuccessPopUp
                title={formType === 'Owner' ? 'Account created successfully!' : 'Request sent successfully!'}
                subtitle={formType === 'Owner' ? <>Please check your inbox at {form.email} for an email containing information on the next steps.</> : <>Your advertising request was submitted successfully. <br/> We'll be in touch with you soon.</>}
            />
        </Modal>
        }
    </>
}

export default Home;