import {configureStore, combineReducers} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AuthSlice from './reducers/Auth/AuthSlice';
import {AuthApi} from "./reducers/Auth/AuthApi";


const rootReducer = combineReducers({
  [AuthApi.reducerPath]: AuthApi.reducer,
  auth: AuthSlice,
});

const persistConfig = {
  key: 'billboards',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({serializableCheck: false}).concat(AuthApi.middleware),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
