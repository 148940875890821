import cx from 'classnames'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { NumericFormat, PatternFormat } from 'react-number-format'
import styles from './style.module.scss'

export interface IInput {
  type?: 'text' | 'textarea' | 'email' | 'tel' | 'number' | 'password'
  error?: string | null
  onChange: (value: any, formattedValue?: any, floatValue?: any) => void
  readonly?: boolean
  value: any
  placeholder?: string
  label?: string | null
  col?: 'col12' | 'col6' | 'col4' | 'col3' | 'col2' | 'unset'
  name: string
  pattern?: string | undefined
  mask?: string
  tabIndex?: number
  controlZero?: boolean
  allowZero?: boolean
  onBlur?: (e: any) => void
  prefix?: string
  className?: string
  hideErrorMessage?: boolean
  inputBg?: 'transparent' | 'white'
  disabled?: boolean
  enableDecimal?: boolean
  fixedDecimalScale?: boolean
  decimalScale?: number | undefined
  thousandSeparator?: boolean
  isAllowed?: (val: any) => boolean
  isLabelLarge?: boolean
  noWrapperPadding?: boolean
  autoComplete?: string
  hideEyeIcon?: boolean
  size?: 'default' | 'sm',
  labelPlacement?:'outer' | 'inner'
}
const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      type = 'text',
      error = null,
      onChange,
      readonly = false,
      value,
      placeholder = '',
      label = null,
      col = 'col12',
      name,
      pattern,
      mask,
      tabIndex,
      controlZero,
      allowZero,
      onBlur,
      prefix,
      className,
      hideErrorMessage,
      inputBg = 'transparent',
      disabled,
      enableDecimal = false,
      fixedDecimalScale = true,
      decimalScale = 0,
      thousandSeparator = true,
      noWrapperPadding,
      isAllowed,
      isLabelLarge = false,
      autoComplete,
      hideEyeIcon,
      size = 'default',
      labelPlacement = 'outer',
      ...props
    },
    ref
  ) => {
    const wrapperRef = useRef<HTMLInputElement>(null)
    const innerLabelRef = useRef<HTMLLabelElement>(null)
    const [isShowPassword, setIsShowPassword] = useState(false)

    useEffect(() => {
      if (labelPlacement !== 'inner' || !innerLabelRef.current || !wrapperRef.current) return
      wrapperRef.current.style.setProperty('--inner-label-width', `${innerLabelRef.current.clientWidth + 4}px`)
    },[label, labelPlacement])

    return (
      <div
        ref={wrapperRef}
        className={cx(
          styles[col],
          styles.inputWrapper,
          { [styles.errorWrapp]: error, [styles.noPadding]: noWrapperPadding },
          styles[size],
          className,
          className && styles[className]
        )}>
        {label && labelPlacement === 'outer' && <label className={`${isLabelLarge ? styles.large : ''}`}>{label}</label>}
        <div className={styles.inputInner}>
        {label && labelPlacement === 'inner' && <label ref={innerLabelRef} className={styles.labelInner}>{label}:</label>}
          {type === 'number' && !mask ? (
            <>
              <NumericFormat
                getInputRef={ref}
                disabled={disabled}
                tabIndex={tabIndex}
                allowLeadingZeros={false}
                allowNegative={false}
                autoFocus={tabIndex && tabIndex === 1 ? true : false}
                isAllowed={(val: any) => {
                  if (isAllowed) {
                    return isAllowed(val)
                  } else {
                    return !controlZero ? (allowZero && val.value.length === 1 ? true : val.floatValue !== 0) : true
                  }
                }}
                value={value}
                thousandSeparator={thousandSeparator}
                onValueChange={(v: any) => onChange(v.value, v.floatValue, v.formattedValue)}
                onBlur={onBlur}
                placeholder={placeholder}
                prefix={prefix}
                decimalSeparator={'.'}
                fixedDecimalScale={false}
                decimalScale={decimalScale}
                readOnly={readonly}
                className={cx(styles.input, styles[type], { [styles.whiteBg]: inputBg === 'white' })}
                autoComplete={autoComplete}
              />{' '}
            </>
          ) : (type === 'tel' || type === 'number') && mask ? (
            <PatternFormat
              getInputRef={ref}
              disabled={disabled}
              format={mask}
              value={value}
              onValueChange={(v: any) => onChange(v.value, v.floatValue, v.formattedValue)}
              onBlur={onBlur}
              placeholder={placeholder}
              prefix={prefix}
              readOnly={readonly}
              autoComplete={autoComplete}
              className={cx(styles.input, styles[type], { [styles.whiteBg]: inputBg === 'white' })}
            />
          ) : type === 'textarea' ? (
            <textarea
              disabled={disabled}
              className={cx(styles.input, styles[type], { [styles.whiteBg]: inputBg === 'white' })}
              onChange={onChange}
              readOnly={readonly}
              value={value}
              placeholder={placeholder}
              name={name}
              autoComplete={autoComplete}
              onBlur={onBlur}
            />
          ) : (
            <input
              ref={ref}
              disabled={disabled}
              className={cx(styles.input, styles[type], { [styles.whiteBg]: inputBg === 'white' })}
              type={type === 'password' && !hideEyeIcon ? (isShowPassword ? 'text' : type) : type}
              onChange={onChange}
              onBlur={onBlur}
              readOnly={readonly}
              value={value}
              placeholder={placeholder}
              name={name}
              autoComplete={autoComplete}
              pattern={pattern}
            />
          )}
          {(!!error || type === 'password') && (
            <div className={styles.icons}>
              {error && <i className={cx(styles.icon, styles.errorIcon, 'icon-attention-circle')} />}
              {type === 'password' && !hideEyeIcon && (
                <button
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  className={cx(styles.icon, styles.eyeIcon, isShowPassword ? 'icon-eye' : 'icon-eye-slash')}
                />
              )}
            </div>
          )}
        </div>
        {error && error !== '' && !hideErrorMessage && <span className={styles.error}>{error}</span>}
      </div>
    )
  }
)

export default Input
