import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import PasswordChecklist from "react-password-checklist"
import check from '../../../../assets/images/icons/check-mark-green.svg';
import close from '../../../../assets/images/icons/close-red.svg';
import {IRegister, isPhoneValid} from "../../../store/reducers/Auth/AuthTypes";
import Button from "../Button";
import Input from "../Input";
import InputPhone from "../InputPhone";
import Checkbox from "../CheckBox";
import {REG_EXP} from "../../../utils/global";

interface ISignUp {
    form: IRegister;
    onSignUp: () => void;
    onChange: (value: string | boolean, key: string) => void;
    onSignInClick?: () => void;
    userType: "Advertiser" | "Owner";
}

const SignUp = ({ form, onSignUp, onChange, onSignInClick, userType = 'Owner' }: ISignUp) => {
    const [isValidate, setIsValidate] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const validate = () => {
        if (userType === 'Owner') {
            setIsValidate(form.name && form.name.length > 1
                && form.surname && form.surname.length > 1
                && (isPhoneValid(form.phone))
                && form.email && form.email.length > 4 && Boolean(form.email.match(REG_EXP))
                && form.password && form.password.length > 9
                && form?.userType && isPasswordValid
                && form?.agreePolicy);
        } else {
            setIsValidate(form.name && form.name.length > 1
                && form.surname && form.surname.length > 1
                && (isPhoneValid(form.phone))
                && form.description
                && form.email && form.email.length > 4 && Boolean(form.email.match(REG_EXP)));
        }

    }

    useEffect(() => {
        validate();
        // eslint-disable-next-line
    }, [form, isPasswordValid]);

    return (
        <>
            <h3 className={`h2 ${style.modalTitle}`}>{userType === 'Owner' ? 'Own Ad Space?' : 'Want to advertise?'}</h3>
            <p className={style.modalSubtitle}>{userType === 'Owner' ? <>Add your inventory, fill your signs, and fill them faster! <br/> Create an account and get the next steps by email.</> : <>Advertise smarter by targeting your ads in specific physical locations. <br/> Send a request and we will be in touch to assist!</>}</p>
            <div className={style.signForm}>
                <div className={`form-row ${style.formRow} ${style.rowDirectionColumn}`}>
                    <Input
                        onChange={e => onChange(e.target.value, 'name')}
                        value={form.name}
                        name={'name'}
                        placeholder={'First Name'}
                    />
                    <Input
                        onChange={e => onChange(e.target.value, 'surname')}
                        value={form.surname}
                        name={'surname'}
                        placeholder={'Last Name'}
                    />
                </div>
                <div className={`form-row ${style.formRow}`}>
                    <Input
                        onChange={e => onChange(e.target.value, 'company')}
                        value={form.company}
                        name={'company'}
                        placeholder={'Company'}
                    />
                </div>
                <div className={`form-row ${style.formRow}`}>
                    <InputPhone
                        phone={form.phone}
                        onFormChange={(v) => onChange(v, 'phone')}
                        error={form.phone.length > 5 && !isPhoneValid(form.phone) ? 'Invalid phone number' : ''}
                    />
                </div>
                <div className={`form-row ${style.formRow}`}>
                    <Input
                        onChange={e => onChange(e.target.value, 'email')}
                        value={form.email}
                        name={'email'}
                        placeholder={'Email'}
                        type={'email'}
                        error={form.email.length > 4 && !form.email.match(REG_EXP) ? 'Invalid email' : ''}
                    />
                </div>
                {userType === 'Owner' ? <>
                    <div className={`form-row ${style.formRow}`}>
                        <Input
                            onChange={e => onChange(e.target.value, 'password')}
                            value={form.password}
                            name={'password'}
                            placeholder={'Password'}
                            type={'password'}
                        />
                    </div>
                    <div className={`form-row ${style.formRow}`}>
                        <Input
                            onChange={e => onChange(e.target.value, 'confirmPassword')}
                            value={form.confirmPassword}
                            name={'confirmPassword'}
                            placeholder={'Confirm Password'}
                            type={'password'}
                        />
                        {form.password.length > 0 && <div className={style.passwordRules}>
                            <PasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                minLength={10}
                                value={form.password}
                                valueAgain={form.confirmPassword}
                                iconComponents={{ValidIcon: <div className={style.validIcon}><img src={check} alt={'Valid'}/></div>, InvalidIcon: <div className={style.validIcon}><img src={close} alt={'Invalid'}/></div>}}
                                onChange={(isValid: boolean) => {
                                    setIsPasswordValid(isValid)
                                    validate();
                                }}
                            />
                        </div>}
                    </div>
                </> : <>
                    <div className={`form-row ${style.formRow}`}>
                        <Input
                            onChange={e => onChange(e.target.value, 'description')}
                            value={form.description}
                            name={'description'}
                            type={'textarea'}
                            placeholder={'Tell us about your business'}
                        />
                    </div>
                </>}

                <div className={style.copyText}>
                    {userType === 'Owner' ?
                        <Checkbox
                            checked={'agreePolicy' in form ? form.agreePolicy : false}
                            name={'agreePolicy'}
                            label={<>By creating account I agree to <Link to={'/'} target={'_blank'}>Terms & Conditions</Link> and <Link target={'_blank'} to={'/'}>Privacy Policy</Link>. </>}
                            onChange={(e) => onChange(e.target.checked, 'agreePolicy')}
                        />
                    :
                        <Checkbox
                            checked={'needDesignHelp' in form ?  form.needDesignHelp : false}
                            name={'needDesignHelp'}
                            label={<>I need Ad Design Solutions</>}
                            onChange={(e) => onChange(e.target.checked, 'needDesignHelp')}
                        />
                    }
                </div>
                <div className={`form-row ${style.formRowButton}`}>
                    <Button title={userType === 'Owner' ? 'Create Account' : 'Send Request'} theme={'blue'} disabled={!isValidate} onClick={onSignUp} />
                </div>
            </div>
        </>
    )

}
export default SignUp;