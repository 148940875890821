import { PhoneNumberUtil } from 'google-libphonenumber';
export interface IRegister {
    email: string,
    password: string,
    confirmPassword?: string,
    name: string,
    surname: string,
    phone: string,
    company: string,
    userType: "Advertiser" | "AdSpaceOwner",
    description: string,
    needDesignHelp?: boolean,
    agreePolicy?: boolean
}

export interface IOption<T extends any = string | number> {
    value: T;
    label: string;
}
export interface IOptionExtended<P, V extends any = string | number> extends IOption<V> {
    properties?: P
}

export enum UserTypesEnum {
    ADVERTISER = 'Advertiser',
    SPACE_OWNER = 'AdSpaceOwner',
    BOTH = 'AdvertiserAndAdSpaceOwner'
}
export const USER_TYPES_OPTIONS = [
    { label: 'Advertiser', value: UserTypesEnum.ADVERTISER},
    { label: 'Ad space owner', value: UserTypesEnum.SPACE_OWNER},
    { label: 'Both', value: UserTypesEnum.BOTH},
]
const phoneUtil: any = PhoneNumberUtil.getInstance()
export const isPhoneValid = (phone: string) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
        return false
    }
}