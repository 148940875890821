import cn from 'classnames'
import { JSX, MouseEvent, createElement, useId, useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import styles from './style.module.scss'
interface IButton {
  url?: string
  theme?: 'primary' | 'secondary' | 'default' | 'border' | 'blue' | 'secondary-light' | 'default-accent'
  noPadding?: boolean
  disabled?: boolean
  icon?: string | null
  title: string
  onClick?: any
  isLoading?: boolean
  fullWidth?: boolean
  className?: string
  stopPropagation?: boolean
  customClassName?: string
  fontWeight?: 400 | 500 | 700
  tooltip?: JSX.Element
  tooltipTheme?: 'light' | 'blue'
  size?: 'small' | 'medium' | 'large'
  asHtmlElement?: keyof JSX.IntrinsicElements
}
const Button = ({
  url = '',
  theme = 'primary',
  disabled = false,
  icon = null,
  title,
  noPadding,
  onClick = null,
  isLoading = false,
  fullWidth = false,
  className,
  stopPropagation,
  customClassName = '',
  fontWeight = 400,
  tooltip,
  tooltipTheme,
  size = 'large',
  asHtmlElement,
  ...props
}: IButton) => {
  const tooltipId = useId()

  const buttonHandle = (e: MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation()
    }
    if (onClick) {
      onClick(e)
    }
  }

  const toolTipProps = useMemo(() => {
    if (!tooltip) return {}
    return {
      'data-tooltip-id': tooltipId,
      'data-tooltip-html': renderToStaticMarkup(tooltip),
    }
  }, [tooltip, tooltipId])

  const childElements: JSX.Element = (
    <>
      {icon && (
        <span className={styles.imageIcon}>
          <img src={icon} alt={''} />
        </span>
      )}
      {/*{icon  && <span className={`${styles.textIcon} ${icon}`}/>}*/}
      <span className={styles.buttonText}>{title}</span>
      {tooltip && <Tooltip className={cn('global-tooltip', { [`theme-${tooltipTheme}`]: !!tooltipTheme })} id={tooltipId} place="right" variant="light" />}
    </>
  )

  if (url && url !== '') {
    return (
      <Link
        {...props}
        {...toolTipProps}
        to={url}
        style={{ fontWeight }}
        className={` ${styles.button} ${className} ${styles[noPadding ? 'noPadding' : '']} ${styles[theme]} ${styles[disabled ? 'disabled' : '']} ${
          fullWidth ? styles.fullWidth : ''
        } ${styles[customClassName]} ${styles[size]}`}
        onClick={buttonHandle}>
        {childElements}
      </Link>
    )
  }
  if (asHtmlElement) {
    return createElement(
      asHtmlElement,
      {
        ...props,
        ...toolTipProps,
        style: { fontWeight },
        className: `${styles.button} ${className} ${styles[noPadding ? 'noPadding' : '']} ${styles[theme]} ${styles[disabled ? 'disabled' : '']} ${
          fullWidth ? styles.fullWidth : ''
        } ${styles[customClassName]} ${styles[size]}`,
        onClick: buttonHandle,
        disabled,
      },
      !isLoading ? childElements : 'Loading...'
    )
  }
  return (
    <button
      {...props}
      {...toolTipProps}
      style={{ fontWeight }}
      className={`${styles.button} ${className} ${styles[noPadding ? 'noPadding' : '']} ${styles[theme]} ${styles[disabled ? 'disabled' : '']} ${
        fullWidth ? styles.fullWidth : ''
      } ${styles[customClassName]} ${styles[size]}`}
      onClick={buttonHandle}
      disabled={disabled}>
      {!isLoading ? childElements : 'Loading...'}
    </button>
  )
}

export default Button
